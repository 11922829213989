<template>
  <div class="cont">
    <div class="table-box">
      <div class="goBack"><el-link class="l-height-50 back_iconfont" type="primary" :underline="false" icon="iconfont iconfanhui1" @click="goBack()">返回</el-link></div>
      <!-- 员工信息 -->
      <el-table
          ref="mainTable"
          :data="personalData"
          style="width: 100%"
          :stripe="true"
          >
        <el-table-column prop="receiveDuty" label="员工职位" min-width="110"></el-table-column>
        <el-table-column prop="receiveName" label="员工姓名" min-width="140"></el-table-column>
        <el-table-column prop="receivePhone" label="联系方式" min-width="140"></el-table-column>
        <el-table-column prop="receiveOrgName" label="所属部门" min-width="90"></el-table-column>
        <el-table-column prop="todayCount" label="今日完成单量" min-width="200"></el-table-column>
        <el-table-column prop="totalCount" label="全部单量" min-width="150"></el-table-column>
        <el-table-column label="状态" min-width="150">
          <template slot-scope="scope">{{scope.row.taskStatus | filterTaskState}}</template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 表头 -->
    <el-row class="search" style="margin-bottom: 0">
      <span class="label">工单状态：</span>
      <el-select  v-model="status" clearable placeholder="请选择">
        <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select>
      <span class="label">生成时间：</span>
      <date-packer :datePacker="orderDateVal" @setDatePacker="getOrderDatePacker"></date-packer>
      <el-button class="m-bottom-5 bg-gradient" type="primary" icon="el-icon-search"  @click="handleQuery(true)"><span v-text="$t('msg.search')">搜索</span></el-button>
      <el-button class="m-bottom-5" type="primary" icon="el-icon-refresh-right" @click="handleQuery(false)"  plain><span v-text="$t('msg.reset')">重置</span></el-button>
    </el-row>
    <!-- 主体内容 -->
    <div class="table-box">
      <el-table
          ref="mainTable"
          v-loading="loading"
          :data="tableData"
          style="width: 100%"
          :stripe="true"
          >
        <el-table-column label="序号" width="60">
          <template slot-scope="scope">
            {{scope.$index + 1}}
          </template>
        </el-table-column>
        <el-table-column prop="receiveDuty" label="员工职位" min-width="110"></el-table-column>
        <el-table-column prop="receiveName" label="姓名" min-width="140"></el-table-column>
        <el-table-column prop="receivePhone" label="联系方式" min-width="140"></el-table-column>
        <el-table-column prop="receiveOrgName" label="所属部门" min-width="90"></el-table-column>
        <el-table-column prop="title" label="工单类型" min-width="200"></el-table-column>
        <el-table-column prop="createTime" label="生成时间" min-width="150"></el-table-column>
        <el-table-column label="任务状态" min-width="150">
          <template slot-scope="scope">{{scope.row.status | filterState}}</template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页 -->
    <pagination :total="total" :page-size="limit" @handleSizeChangeSub="changePageNum" @handleCurrentChangeSub="changeCurrPage"/>
  </div>
</template>
<!--return-->
<script>
import { urlObj } from '@/api/interface'
import {hotelMan} from "@/api/interface/oms";
export default {
  data(){
    return{
      personalData: [],  // 个人信息
      tableData:[],      // 详情列表
      total:0,           // 总条数
      limit:0,           // 每页数
      page:1,            // 当前页
      loading: true,     // 加载效果
      receiveId:'',      // 接收ID
      generated:'',      // 生成时间
      status:'',         // 工单状态
      options:[          //所有工单状态
        {label:'已接单',value:'ORDER_RECEIVED'},
        {label:'已完成',value:'SERVICE_FINISHED'},
      ],
                          //日期
      dateValue: '',      // 设备新增时间段
      beginTime: '',      // 设备新增开始时间
      endTime: '',        // 设备新增结束时间
      orderDateVal: [],   // 订单统计时间段
      orderBeginTime: '', // 订单统计开始时间
      hotelId:'',         //酒店  ID
      action:''
    }
  },
  beforeCreate() {
    sessionStorage.removeItem('personalData')
  },
  mounted() {
    this.hotelId = sessionStorage.getItem('hotelId')
    if (this.hotelId === undefined){
      this.hotelId = '2020062814553216'
    }
    let personalData = JSON.parse(sessionStorage.getItem('personalDatas'))
    this.limit = sessionStorage.getItem('pageSize') * 1
    this.receiveId = this.$route.query.row
    this.action = this.$route.query.action
    if(this.action === 'staff_assessment'){
    this.receiveId = personalData.receiveId
    this.personalData.push(personalData)
    }
    this.getDetails()
    this.routes = this.$route.query.routes
    if(this.routes === 'Home'){
      this.getAssessments()
    }
  },
  methods: {
    // 获取详情列表
    getDetails(){
      const url = hotelMan.assessmentDetails
      const param = {
        receiveId : this.receiveId,
        page: this.page,
        limit: this.limit,
        status:this.status,
        beginDate:this.orderDateVal[0],
        endDate:this.orderDateVal[1],
        hotelId:this.hotelId
      }
      this.$axios.post(url, param).then(res => {
        this.loading = false
        this.total = res.total
        this.tableData = res.records
      })
    },
    getAssessments(){
      const url = hotelMan.assessments
      const param = {
        receiveOrgId: this.receiveOrgId,
        receiveName: this.receiveName,
        page: this.page,
        limit: this.limit,
        hotelId:this.hotelId
      }
      this.$axios.post(url, param).then(res => {
        let arr = []
        arr = res.records
        arr.map(item=>{
          if(item.receiveId == this.receiveId){
            this.personalData.push(item)
          }
        })

      })
    },
    //搜索订单
    handleQuery(bool){
      if (bool) return this.getDetails()
        this.orderDateVal = []
        this.status = ''
    },

    // 改变每页数
    changePageNum(num){
      this.limit = num
      this.getDetails()
    },
    // 改变当前页
    changeCurrPage(num){
      this.page = num
      this.getDetails()
    },
    // 返回上页
    goBack(){
      this.$router.go(-1)
    },
    // 按订单统计时间段过滤
    getOrderDatePacker(val){
      this.orderDateVal = val
      if (this.orderDateVal) {
        this.orderBeginTime = this.orderDateVal[0]
      } else {
        this.orderBeginTime = ''
      }
    },
  },
  filters:{
    filterTaskState(data){
      if(data == 0){
        return '空闲'
      }else{
        return '忙碌'
      }
    },
    filterState(data){
      if(data == "SERVICE_FINISHED"){
        return '已完成'
      }else{
        return '处理中'
      }
    }
  }
}
</script>

<style lang="scss">
.label{margin-left:5px;}
tbody .el-table__row td{text-align: center;}
.has-gutter tr th{text-align: center;}
.el-table th.is-leaf{text-align: center;}
.goBack{background: white; margin-bottom:10px;}
.l-height-50{width: 5rem; text-align: center;}
.search{margin: 1rem 0rem; padding: 1rem 0rem;}
</style>
